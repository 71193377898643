body {
  margin: 0px;
  padding: 0;
	font-family: 'Graphik';
	height: 100vh;
	background: #fff;
}
#root {
  justify-content: center;
	background-color: #fff;
	height: 100%;
}

@font-face {
  font-family: Ultra System Sans;
  src: url("theme/fonts/Ultra_System_Sans/Ultra System Sans.otf");
  font-style: normal;
}

@font-face {
  font-family: Ultra System Sans;
  src: url("theme/fonts/Ultra_System_Sans/Ultra System Sans Italic.otf");
  font-style: italic;
}

@font-face {
  font-family: Ultra System Sans Outline;
  src: url("theme/fonts/Ultra_System_Sans/Ultra System Sans Line Two.otf");
}

@font-face {
  font-family: Ultra System Sans Outline;
  src: url("theme/fonts/Ultra_System_Sans/Ultra System Sans Line Two Italic.otf");
  font-style: italic;
}

@font-face {
  font-family: Graphik;
  src: url("theme/fonts/Graphik/Graphik-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: Graphik;
  src: url("theme/fonts/Graphik/Graphik-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: Graphik;
  src: url("theme/fonts/Graphik/Graphik-Semibold.otf");
  font-weight: 600;
}

@font-face {
  font-family: Graphik;
  src: url("theme/fonts/Graphik/Graphik-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: Yeseva One;
  src: url("theme/fonts/Yeseva_One/YesevaOne-Regular.ttf");
}

@font-face{
  font-family: Gobold Regular;
  src:url("theme/fonts/Gobold/Gobold Regular.otf")
}

@font-face{
  font-family: Gobold Hollow;
  src:url("theme/fonts/Gobold/Gobold Hollow.otf")
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}

ul li::before {
  content    : "\2022";
  color      : #FFC938;
  display    : inline-block;
  font-weight: bold;
  margin-left: -1em;
  width      : 1em;
}
